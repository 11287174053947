import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)

const routes = [
  {
    path: '/sign/index/:taskId',
    components: require('@/views/sign/index.vue')
  },
  {
    path: '/sign/mobile/:taskId',
    components: require('@/views/sign/mobile.vue')
  },
  {
    path: '/sign/preview/:token',
    components: require('@/views/sign/preview.vue')
  },
  {
    path: '/sign/preview/:token/:docId',
    components: require('@/views/sign/preview.vue')
  },
  {
    path: '/error',
    name: 'error',
    components: require('@/views/common/error.vue')
  },
  {
    path: '*',
    components: require('@/views/common/404.vue')
  }
]

export default new Router({
  mode: 'history',
  base: process.env.NODE_ENV !== 'production' ? '/' : process.env.BASE_URL,
  linkActiveClass: 'is-active',
  routes
})
