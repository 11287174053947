import Vue from 'vue'
import App from './App.vue'
import router from '@/router'
import VueCookie from 'vue-cookie'
import './icons'
import './assets/scss/index.scss'
import httpRequest from './utils/httpRequest'

import {
    Button,
    Collapse,
    CollapseItem,
    Dialog,
    Drawer,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    Input,
    Link,
    Loading,
    Message,
    MessageBox,
    Popover,
    Select,
    Table,
    TableColumn,
    Tag
} from 'element-ui'

Vue.use(Input)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Tag)
Vue.use(Dialog)
Vue.use(Drawer)
Vue.use(Button)
Vue.use(Link)
Vue.use(Popover)
Vue.use(Select)
Vue.use(Dropdown)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Loading.directive)
Vue.use(Collapse)
Vue.use(CollapseItem)

Vue.use(VueCookie)
Vue.prototype.$http = httpRequest
Vue.prototype.$loading = Loading.service
Vue.prototype.$msgbox = MessageBox
Vue.prototype.$alert = MessageBox.alert
Vue.prototype.$confirm = MessageBox.confirm
Vue.prototype.$prompt = MessageBox.prompt
Vue.prototype.$message = Message
Vue.prototype.baseUrl = process.env.NODE_ENV !== 'production' ? '/' : process.env.BASE_URL
Vue.config.productionTip = false

require('es6-promise').polyfill()

new Vue({
    router,
    render: h => h(App)
}).$mount('#app')
