const CryptoJS = require('crypto-js')

export default {
  data () {
    return {
    }
  },
  created () {
  },
  methods: {
    error (msg) {
      this.$router.replace({
        name: 'error',
        query: {
          msg: msg
        }
      })
    },
    getSign(value, key){
      return CryptoJS.HmacSHA1(CryptoJS.SHA1(value).toString(CryptoJS.enc.Hex),CryptoJS.SHA1(key).toString(CryptoJS.enc.Hex)).toString(CryptoJS.enc.Hex)
    },
    encrptMobile(mobile) {
      if (mobile && mobile.length > 6) {
        return mobile.substr(0, 3) + (mobile.substr(3, mobile.length - 6).replace(/\d/g, '*')) + mobile.substr(mobile.length - 3)
      }
      return mobile
    },
    goback () {
      if(window.isWeixin){
        // 微信
        wx.miniProgram.getEnv((res) => {
          if (res.miniprogram) {
            // 小程序
            wx.miniProgram.navigateBack()
          } else {
            // 微信网页
            if (history.length>1) {
              // 有上一个页面，返回上一个页面
              history.back()
            } else {
              // 没有上一个页面，关闭网页
              wx.closeWindow()
            }
          }
        })
      } else if (window.isJsgz) {
        // 即时公证
        history.back()
      } else {
        // 浏览器网页
        if (history.length>1) {
          history.back()
        } else {
          window.close()
        }
      }
    }
  }
}
