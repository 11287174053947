<template>
    <div class="handwritten">
        <div class="handwritten-title">请手写并提交签名</div>
        <div class="handwritten-body">
            <vue-esign ref="esign" :width="800" :height="480" :isCrop="isCrop" :lineWidth="lineWidth" :lineColor="lineColor" :bgColor.sync="bgColor" />
        </div>
        <div class="handwritten-tip">签名图样将用于对签署文件署名</div>
        <div class="handwritten-footer">
            <el-button type="info" @click="handleReset">重新绘制</el-button>
            <el-button type="primary" @click="handleGenerate">采用</el-button>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue'
    import vueEsign from 'vue-esign'
    Vue.use(vueEsign)

    export default {
        name: "handwritten",
        data () {
            return {
                lineWidth: 6,
                lineColor: '#000000',
                bgColor: '',
                resultImg: '',
                isCrop: true
            }
        },
        methods: {
            message (type, message) {
                var options = {
                    type: type,
                    message: message,
                    customClass: 'el-message-h5'
                }
                this.$message(options)
            },
            alert(message, type) {
                var options = {
                    customClass: 'el-message-box-h5',
                    dangerouslyUseHTMLString: true,
                    center: true,
                    showClose: false
                }
                if (type == 'success') {
                    return this.$alert('<img src="' + this.baseUrl + 'static/images/success.png"/><div>'+message+'</div>', '', options)
                } else {
                    return this.$alert('<img src="' + this.baseUrl + 'static/images/fail.png"/><div>'+message+'</div>', '', options)
                }
            },
            handleReset () {
                this.$refs.esign.reset()
            },
            handleGenerate () {
                this.$refs.esign.generate().then(res => {
                    this.$emit('useSignature', res);
                }).catch(err => {
                    this.alert('请先手写个人签名');
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    .handwritten{
        .handwritten-title{
            line-height: 2.2;
            font-size: 16px;
            text-align: center;
            color: #333;
            background: #FFF;
        }
        .handwritten-body{
            background: #E3E5E7;
        }
        .handwritten-tip{
            color: #666;
            font-size: 12px;
            padding: 10px 10px 0;
        }
        .handwritten-footer{
            padding: 10px;
            .el-button{
                width: 48%;
            }
        }
    }
</style>
