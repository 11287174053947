import Vue from 'vue'
// import {PDFJS} from 'pdfjs-dist'
const CryptoJS = require('crypto-js')
const PDFJS = require("pdfjs-dist")
// PDFJS.GlobalWorkerOptions.workerSrc = require('pdfjs-dist/build/pdf.worker')
// PDFJS.GlobalWorkerOptions.workerSrc = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.6.347/pdf.worker.js'
// import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry'; 1--OK
// PDFJS.GlobalWorkerOptions.workerSrc = pdfjsWorker; 1--OK
// PDFJS.GlobalWorkerOptions.workerSrc = require("pdfjs-dist/build/pdf.worker.min")
const cMapUrl = process.env.BASE_URL + 'static/cmaps/'
// PDFJS.GlobalWorkerOptions.cMapUrl = process.env.BASE_URL + 'static/cmaps/'
// PDFJS.GlobalWorkerOptions.cMapPacked = true
//standardFontDataUrl
// PDFJS.cMapUrl = process.env.BASE_URL + 'static/cmaps/'
// PDFJS.cMapPacked = true

// import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry'
// PDFJS.GlobalWorkerOptions.workerSrc = pdfjsWorker
PDFJS.GlobalWorkerOptions.cMapUrl = cMapUrl
PDFJS.GlobalWorkerOptions.cMapPacked = true
// import { TextLayerBuilder, EventBus } from 'pdfjs-dist/web/pdf_viewer'
import 'pdfjs-dist/web/pdf_viewer.css'
export default {
    data() {
        return {
            fileHash: ''
        }
    }, created() {
    }, methods: {
        loadFiles() {
            for (var i = 0; i < this.files.length; i++) {
                this.loadFile(i)
            }
        }, // 加载pdf文件
        loadFile(fileIndex) {
            // return new Promise((resolve, reject) => {
            //
            // })
            if (fileIndex >= this.files.length) {
                return
            }
            let file = this.files[fileIndex]
            console.log('file.fileUrl = ' + file.fileUrl)
            //console.log('  PDFJS.GlobalWorkerOptions.workerSrc2 = ' +   PDFJS.GlobalWorkerOptions.workerSrc)
            let pdfObj = {
                url: file.fileUrl, cMapUrl: cMapUrl, cMapPacked: true
                // , disableRange: true
                // , disableStream: true
            };
            console.log("cMapUrl = " + cMapUrl)
            let loadingTask = PDFJS.getDocument(pdfObj);
            console.log(' loadingTask @@@@@@@@@@@@@@@@@@@@@@@@@ ')
            loadingTask.promise.then(pdfDocumentProxy => {
                // 文件hash
                file.fileHash = pdfDocumentProxy.fingerprint;
                // 文件已加载
                file.loaded = true;
                // 文件页数
                file.totalPage = pdfDocumentProxy.numPages
                // pdf文档
                file.pdf = pdfDocumentProxy
                console.log(file.totalPage + '页'+' , pdf文件的fingerprint = ' + pdfDocumentProxy.fingerprint  + ' , file文件的fileHash = ' + file.fileHash)
                this.getAllFileHash()
                // 先渲染第一个文件
                if (fileIndex === 0) {
                    // 加载完成之后，渲染第一页
                    this.renderPage(fileIndex, 0)
                }
            });
        }, getAllFileHash() {
            try{
                console.log('加载PDF文件。。。。。。。。')
                let strs = []
                let flag = false
                for (var i = 0; i < this.files.length; i++) {
                    var file = this.files[i]
                    console.log('文件fileUrl = ' + file.fileUrl + ' , 文件hash = ' + file.fileHash)
                    if (!file.fileHash) {
                        flag = true
                        break
                    }
                    strs.push(file.fileHash)
                }
                if (!flag) {
                    this.fileHash = CryptoJS.MD5(strs.join(',').toUpperCase()).toString().toUpperCase()
                    console.log("聚合的HASH = " + this.fileHash)
                }
            } catch (e) {
                console.log(e.message)
            }
        }, // 渲染pdf
        renderPage(fileIndex, pageIndex) {
            let pdfFile = this.files[fileIndex]
            if (!pdfFile.loaded) {
                // 如果文件没有加载完成，等待文件加载完成
                setTimeout(() => {
                    this.renderPage(fileIndex, pageIndex)
                }, 100);
                return
            }
            var totalPage = pdfFile.totalPage
            var width = document.getElementById('0-0').offsetWidth
            pdfFile.pdf.getPage(pageIndex + 1).then((page) => {
                var t1 = new Date().getTime()
                // var viewport = page.getViewport(1)
                let viewport = page.getViewport({"scale": 1});
                // 文字fontScale
                Vue.set(this.fs, fileIndex + '-' + pageIndex, width / viewport.width)
                // 签名和印章宽度
                Vue.set(this.ws, fileIndex + '-' + pageIndex, 120 / viewport.width)
                console.log('getElementById width = ' + width)
                console.log('viewport.width = ' + viewport.width)
                var scale = Math.max(width / viewport.width, 1)
                // var pdfOriginalWidth = viewport.width;
                // var scale = width / viewport.width
                // scale = 2.2
                console.log('scale = ' + scale)
                let scaledViewport = page.getViewport({"scale": scale})
                var canvas = document.getElementById('pdf-' + fileIndex + '-' + pageIndex)
                var textLayerWrapper = document.getElementById('pdfTextLayer-' + fileIndex + '-' + pageIndex)
                if (canvas) {
                    console.log('canvas is true>>>')
                    var context = canvas.getContext('2d')
                    canvas.width = scaledViewport.width
                    canvas.height = scaledViewport.height

                    // canvas.style.width = width + 'px'
                    canvas.style.width = '100%'
                    canvas.style.height = width * scaledViewport.height / scaledViewport.width + 'px'

                    console.log('scaledViewport.width = ' + scaledViewport.width)
                    console.log('scaledViewport.height = ' + scaledViewport.height)
                    // var outputScale = window.devicePixelRatio || 1;
                    // var transform = outputScale !== 1 ? [outputScale, 0, 0, outputScale, 0, 0] : null;
                    var renderContext = {
                        canvasContext: context,
                        // transform: transform,
                        viewport: scaledViewport,
                        // intent: "print",
                        // annotationMode: 3,
                        // textLayer: textLayerWrapper
                        //PDFJS...AnnotationMode.ENABLE_STORAGE,
                    };
                    // var textLayerBuilder = new TextLayerBuilder({
                    //     textLayerDiv: textLayerWrapper,
                    //     pageIndex: page.pageIndex,
                    //     viewport: scaledViewport
                    // });
                    page.render(renderContext).promise.then(() => {
                        var t2 = new Date().getTime()
                        console.log('render pdf ', fileIndex, pageIndex, (t2 - t1) + 'ms')
                        this.renderThumb(fileIndex, pageIndex)
                        Vue.set(this.loading, fileIndex + '-' + pageIndex, true)
                        // textLayerBuilder.render().promise.then(function() {
                        //     textLayerWrapper.style.width = scaledViewport.width + 'px';
                        //     textLayerWrapper.style.height = scaledViewport.height + 'px';
                        // });
                    })

                    // 渲染 textLayer
                    page.getTextContent().then(function(textContent) {
                        PDFJS.renderTextLayer({
                            textContent: textContent,
                            container: textLayerWrapper,
                            viewport: scaledViewport,
                            textDivs: []
                        });
                    });

                    // page.getTextContent().then(function(textContent) {
                    //     textLayerBuilder.setTextContent(textContent);
                    // });
                    // page.getOperatorList().then(function(opList) {
                    //     let svgGfx = new PDFJS.SVGGraphics(page.commonObjs, page.objs)
                    //     svgGfx.getSVG(opList, viewport).then(function(svg) {
                    //         canvas.appendChild(svg)
                    //         //Vue.set(this.loading, fileIndex + '-' + pageIndex, true)
                    //     })
                    // })
                }
                if (pageIndex < totalPage - 1) {
                    setTimeout(() => {
                        this.renderPage(fileIndex, pageIndex + 1)
                    }, 10)
                } else {
                    console.log('一个文件渲染完成，自动渲染下一个文件。')
                    // 当前文件已经渲染完，即将进行下一个文件的渲染
                    var nextFileIndex = fileIndex + 1
                    if (nextFileIndex < this.files.length) {
                        this.renderPage(nextFileIndex, 0)
                    }
                }
            }).catch((e) => {
                console.log('e = ' + e.message)
            })
        }, // 渲染缩略图
        renderThumb(fileIndex, pageIndex) {
            console.log("********renderThumb*************")
            var pdfId = 'pdf-' + fileIndex + '-' + pageIndex
            var thumbId = 'thumb-' + fileIndex + '-' + pageIndex
            var oldCanvas = document.getElementById(pdfId);
            var newCanvas = document.getElementById(thumbId);
            if (newCanvas) {
                var context = newCanvas.getContext('2d');
                newCanvas.height = newCanvas.width * oldCanvas.height / oldCanvas.width;

                context.clearRect(0, 0, newCanvas.width, newCanvas.height);
                context.drawImage(oldCanvas, 0, 0, newCanvas.width, newCanvas.height);
            }
        }
    }
}
