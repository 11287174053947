<template>
  <div id="app">
    <transition name="fade">
      <router-view></router-view>
    </transition>
  </div>
</template>

<script>
  export default {
    name: 'app',
    created () {
      if(navigator.userAgent.indexOf('MicroMessenger') > -1){
        window.isWeixin = true
        var script=document.createElement('script');
        script.type="text/javascript";
        script.src='https://res.wx.qq.com/open/js/jweixin-1.3.2.js';
        // script.src='https://res.wx.qq.com/open/js/jweixin-1.6.0.js';
        // script.src='https://res2.wx.qq.com/open/js/jweixin-1.6.0.js';
        // http://res2.wx.qq.com/open/js/jweixin-1.6.0.js
        document.body.appendChild(script);
      }
      if(navigator.userAgent.indexOf('jsgz') > -1){
        window.isJsgz = true
      }
    },
    methods:{
    }
  }
</script>

<style lang="scss" scoped>
  #app{
    width: 100%;
  }
</style>
