<template>
  <div class="error-404">
    404
  </div>
</template>

<script>
  export default {
    components: {},
    data () {
      return {}
    },
    computed: {},
    created () {
    },
    mounted () {
    },
    methods: {}
  }
</script>

<style lang="scss" scoped>
  .error-404 {
  }
</style>