<template>
  <div class="sign-index" v-loading="contentLoading">
    <div class="gz-head">
      <div class="gz-head-inner">
        <div class="time-area">
          <div class="clock">{{ timeStr }}</div>
        </div>
        <div class="ssgz">您已进入电子公证系统，您的操作将被实时公证记录</div>
        <div class="counter" :style="{background: 'url(' +bangPng + ')'}">距结束：{{ pageCountdownStr }}</div>
        <div class="gzfw">本次任务由{{ task.deptName ? task.deptName : '南京公证处' }}为您提供公证服务</div>
      </div>
    </div>
    <div class="gz-wrap clearfix">
      <div class="gz-left" v-if="!batch"></div>
      <div ref="pageContainer" class="gz-main">
        <div class="doc-list-wrap" v-if="batch">
          <div class="tips">
            <div>您正在对以下 {{ docs.length }}份文书进行批量签署操作</div>
            <div>批量签署前，请您通过预览功能仔细阅读每一份文书内容，以确保文书内容与您真实的意思一致。</div>
            <div>点击下方的“立即签署”按钮，即表示您对文书中的内容无异议。</div>
            <div class="highlight">签署完成即表示以下{{ docs.length }}份文书都已完成签署</div>
          </div>
          <div class="doc-list">
            <div class="doc-item doc-head">
              <div class="doc-index">
                <span>序号</span>
              </div>
              <div class="doc-title">
                <span>待签文书</span>
              </div>
              <div class="doc-partner">
                参与方
              </div>
              <div class="doc-action">
                操作
              </div>
            </div>
            <div class="doc-item" v-for="(doc, docIndex) in docs">
              <div class="doc-index">
                <span>{{ docIndex + 1 }}</span>
              </div>
              <div class="doc-title">
                <span>{{ doc.title }}</span>
              </div>
              <div class="doc-partner">
                <div v-for="partner in doc.partners">
                  {{ partner.partnerName }}
                  <icon-svg v-if="partner.signStatus == 2" name="finish"></icon-svg>
                </div>
              </div>
              <div class="doc-action">
                <a :href="'../preview/' + taskId + '/' + doc.docId" target="_blank">预览</a>
              </div>
            </div>
          </div>
        </div>
        <div class="file-wrapper" v-else>
          <div class="file-view" v-for="(file, fileIndex) in files">
            <div class="page-view" v-for="(page, pageIndex) in file.filePage">
              <div class="paper" :id="fileIndex + '-' + pageIndex" v-loading="!loading[fileIndex + '-' + pageIndex]">
                <canvas class="paper-pdf" :id="'pdf-' + fileIndex + '-' + pageIndex"></canvas>
                <div class="paper-cover" :data-page="pageIndex">
                  <template v-for="sPosition in positions">
                    <template v-if="sPosition.fileIndex === fileIndex && sPosition.pageIndex === pageIndex">
                      <template v-if="sPosition.type ==='seal'">
                        <img class="img-stamp" :src="companyStamp"
                             :style="'top:' + sPosition.y * 100 + '%;left:' + sPosition.x * 100 + '%;width:'+ (sPosition.w>0?sPosition.w:ws[fileIndex + '-' + pageIndex]) * 100 + '%;'"/>
                      </template>
                      <template v-if="userStamp && sPosition.type ==='signature'">
                        <img class="img-stamp" :src="userStamp"
                             :style="'top:' + sPosition.y * 100 + '%;left:' + sPosition.x * 100 + '%;width:'+ (sPosition.w>0?sPosition.w:ws[fileIndex + '-' + pageIndex]) * 100 + '%;'"/>
                      </template>
                      <template v-if="legalStamp && sPosition.type ==='legal'">
                        <img class="img-stamp" :src="legalStamp"
                             :style="'top:' + sPosition.y * 100 + '%;left:' + sPosition.x * 100 + '%;width:'+ (sPosition.w>0?sPosition.w:ws[fileIndex + '-' + pageIndex]) * 100 + '%;'"/>
                      </template>
                      <template v-if="sPosition.type ==='text'">
                        <div class="sign-text"
                             :style="'top:' + sPosition.y * 100 + '%;left:' + sPosition.x * 100 + '%;font-size:' + sPosition.fontSize * fs[fileIndex + '-' + pageIndex] + 'px;'">
                          {{ sPosition.content }}
                        </div>
                      </template>
                    </template>
                  </template>
                </div>
                <div class="textLayer" :id="'pdfTextLayer-' + fileIndex + '-' + pageIndex"></div>
              </div>
              <div class="paper-bottom clearfix">
                <span class="doc-index">- {{ page }}/{{ file.filePage }} -</span><span
                  class="doc-name">{{ file.fileName }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="gz-right" v-if="!batch">
        <el-collapse v-model="activeThumbnails">
          <el-collapse-item :name="fileIndex" class="file-page-wrap" v-for="(file, fileIndex) in files"
                            :key="'thumb_'+fileIndex">
            <template slot="title">
              <div class="file-title">{{ file.fileName }}</div>
            </template>
            <div class="page-wrap">
              <div @click="clickThumb(fileIndex + '-' + pageIndex)" class="page-item"
                   :class="{current: activePage == (fileIndex + '-' + pageIndex)}"
                   v-for="(page, pageIndex) in file.filePage" :key="fileIndex + '-' + pageIndex"
                   v-loading="!loading[fileIndex + '-' + pageIndex]">
                <!--                <pdf-->
                <!--                  :key="fileIndex + '-' + page"-->
                <!--                  :src="fileBaseUrl + file.filePath"-->
                <!--                  :page="page"-->
                <!--                  class="paper-pdf"-->
                <!--                ></pdf>-->
                <canvas :id="'thumb-' + fileIndex + '-' + pageIndex" class="paper-thumb"></canvas>
                <icon-svg v-if="hasSign(fileIndex,pageIndex)" name="mark" class="page-mark"></icon-svg>
                <icon-svg name="corner" class="page-corner"></icon-svg>
                <b>{{ page }}</b>
              </div>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
    </div>
    <div class="gz-bottom">
      <!--关闭情况  -->
      <input class="gzbtn-close" type="button" value="关闭" @click="handlerClose" v-show="signed"/>

      <div class="gz-valid" ref="validForm">
        <!--即时公证码签署-->
        <el-button v-if="task.signMethod === 1" type="primary" @click="momentSign">立即签署</el-button>
        <!--短信验证码签署-->
        <el-button v-if="task.signMethod === 2" type="primary" @click="messageSign">立即签署</el-button>
        <!--签署密码签署-->
        <el-button v-if="task.signMethod === 4" type="primary" @click="airSign">立即签署</el-button>
        <el-button @click="reject" v-if="!batch && task.rejectionSupport">拒绝签署</el-button>
      </div>
    </div>
    <div class="gz-foot">契约互联电子公证服务</div>

    <!--扫码签署弹窗-->
    <el-dialog
        :show-close="false"
        :close-on-click-modal="false"
        :visible.sync="momentSignDialogVisible"
        class="qrcode-dialog"
        width="830px"
        @close="momentClose"
        center>
      <div class="pop-title" slot="title"><img class="logo" :src="baseUrl + 'static/images/logo.png'"
                                               onerror="this.parentElement.style.display='none';"/></div>
      <div class="qrcode-dialog-content">
        <div class="pop-flex">
          <div class="pop-flex-wrap">
            <div class="top-tips">1、请使用您在"{{ system }}"设备上的{{ clientName }}进行扫码</div>
            <div class="qrcode-wrap">
              <img :src="baseUrl + 'static/images/'+ (clientPic||'iphone') +'.png'" style="height: 220px;"/>
              <div class="qrcode-box">
                <qrcode v-if="qrcodeUrl" :value="qrcodeUrl" :options="{ size: 140}"></qrcode>
                <div v-else class="qrcode-ct" v-loading="true"></div>
                <div class="bottom-tips">如果扫码失效<br/>请重新登录您的业务系统，<br/>并重新启动任务</div>
              </div>
            </div>
          </div>
          <div class="pop-flex-wrap">
            <div class="top-tips">2、请在输入框中输入手机APP获取的6位公证签署验证码</div>
            <div class="pop-form">
              <!--<el-input placeholder="请输入短信验证码"></el-input>-->
              <el-input name="code" v-model="smsCode" class="valid-input" type="text" maxlength="6"
                        placeholder="请输入验证码"></el-input>
              <p style="text-align: left;">输入验证码并提交，即表明您自愿接受此文件条款的约束</p>
            </div>
          </div>
        </div>
      </div>
      <div slot="footer">
        <el-button type="default" @click="momentSignDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submit" :loading="btnLoading">提 交</el-button>
      </div>
    </el-dialog>
    <!--扫码签署弹窗-->

    <!--短信签署弹窗-->
    <el-dialog
        :show-close="false"
        :visible.sync="messageSignDialogVisible"
        class="qrcode-dialog"
        width="460px"
        @close="messageClose"
        center>
      <div class="pop-title" slot="title"><img class="logo" :src="baseUrl + 'static/images/logo.png'"
                                               onerror="this.parentElement.style.display='none';"/></div>
      <div class="qrcode-dialog-content">
        <div class="pop-form">
          <div class="pop-input-yzm">
            <el-input v-model="smsCode" placeholder="请输入验证码"></el-input>
            <!--<el-button type="primary">重新获取</el-button>-->

            <el-button type="primary" v-if="smsLeftSeconds <= 0" @click="sendSmsCode">{{ smsBtnText }}</el-button>
            <el-button type="primary" v-else>{{ smsCodeCountdownStr }}</el-button>
          </div>
          <p class="valid-info">
            公证验证码已发送至 <span style="color: #428BCA;">{{ encrptedMobile }}</span>
          </p>
          <p>输入验证码并提交，即表明您自愿接受此文件条款的约束</p>
        </div>
      </div>
      <div slot="footer">
        <el-button type="default" @click="messageSignDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submit">提 交</el-button>
      </div>
    </el-dialog>
    <!--短信签署弹窗-->

    <!--airsign签署弹窗-->
    <el-dialog
        :show-close="false"
        :visible.sync="airSignDialogVisible"
        class="qrcode-dialog"
        width="460px"
        @close="messageClose"
        center>
      <div class="pop-title" slot="title"><img class="logo" :src="baseUrl + 'static/images/logo.png'"
                                               onerror="this.parentElement.style.display='none';"/></div>
      <div class="qrcode-dialog-content">
        <div class="pop-form">
          <p class="valid-info">
            签署码是您在身份认证环节设置的大于6位数的密码
          </p>
          <div class="pop-input-yzm">
<!--            <el-input v-model="smsCode" placeholder="请输入签署码"></el-input>-->
            <el-input placeholder="请输入签署码" v-model="smsCode" auto-complete="new-password" show-password ></el-input>
            <el-popover
                placement="right"
                width="314"
                trigger="click">
              <div>
                <img style="width: 100%;" :src="baseUrl + 'static/images/resetpwd.jpg'" alt="">
              </div>
              <el-link slot="reference" type="primary" :underline="false" class="forget-pass">忘记签署码?</el-link>
            </el-popover>
          </div>
          <p>输入签署码并提交，即表明您自愿接受此文件条款的约束</p>
        </div>
      </div>
      <div slot="footer">
        <el-button type="default" @click="airSignDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submit">提 交</el-button>
      </div>
    </el-dialog>
    <!--airsign签署弹窗-->

    <el-dialog
        title="提示"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :append-to-body="true"
        :show-close="false"
        :visible.sync="statusDialogVisible"
        class="status-dialog"
        width="30%"
        center>
      <template v-if="status == 'success'">
        <div class="status-dialog-content">
          <img :src="successPng"/><br>
          <span>签署成功</span>
        </div>
        <span slot="footer" class="dialog-footer">
              <el-button @click="toCallBackUrl('success')">确定</el-button>
            </span>
      </template>
      <template v-if="status == 'reject'">
        <div class="status-dialog-content">
          <img :src="failPng"/><br>
          <span>拒签成功</span>
        </div>
        <span slot="footer" class="dialog-footer">
              <el-button @click="toCallBackUrl('reject')">确定</el-button>
            </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import Vue from 'vue'
import qrcode from '@xkeshi/vue-qrcode'
import PDFLoader from '../../mixins/pdf-loader'
import Base from '../../mixins/base'
import airsign from '../../utils/airsign'
// import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry'
// const PDFJS = require("pdfjs-dist")
// PDFJS.GlobalWorkerOptions.workerSrc = pdfjsWorker
// PDFJS.GlobalWorkerOptions.cMapUrl = process.env.BASE_URL + 'static/cmaps/'
// PDFJS.GlobalWorkerOptions.cMapPacked = true

// PDFJS.GlobalWorkerOptions.workerSrc = require('pdfjs-dist/build/pdf.worker')
// PDFJS.GlobalWorkerOptions.workerSrc = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.6.347/pdf.worker.js'

// PDFJS.GlobalWorkerOptions.workerSrc = pdfjsWorker;
// PDFJS.GlobalWorkerOptions.cMapUrl = process.env.BASE_URL + 'static/cmaps/'
// PDFJS.GlobalWorkerOptions.cMapPacked = true

// PDFJS.workerSrc = require('pdfjs-dist/build/pdf.worker')
// PDFJS.cMapUrl = process.env.BASE_URL + 'static/cmaps/'
// PDFJS.cMapPacked = true

export default {
  components: {qrcode},
  mixins: [PDFLoader, Base],
  data() {
    return {
      taskId: null,
      pageViews: [],
      pageTimer: null,
      smsCodeTimer: null,
      loading: {},
      ws: {},
      fs: {},
      contentLoading: true,
      statusDialogVisible: false,
      momentSignDialogVisible: false,
      messageSignDialogVisible: false,
      airSignDialogVisible: false,
      btnLoading: false,
      successPng: this.baseUrl + 'static/images/success.png',
      failPng: this.baseUrl + 'static/images/fail.png',
      bangPng: this.baseUrl + 'static/images/bang.png',
      status: '',
      scale: 1.5,
      docObj: [0],
      nextTop: -1,
      activeFile: null,
      activeFilePageNum: 1,
      title: '',
      docType: 0,
      batch: false,
      task: {},
      docs: [],
      files: [],
      positions: {},
      companyStamp: '',
      userStamp: '',
      legalStamp: '',
      activePage: '0-0',
      mobile: null,
      system: '',
      clientName: '',
      clientPic: null,
      userOpenId: '',
      fileHashEncrypted: '',
      count: 60,
      show: true,
      signed: false,
      smsCode: '',
      pageLeftSeconds: 1200,
      smsLeftSeconds: -1,
      smsBtnText: '获取验证码',
      curDate: null,
      loadingInstance: null,
      activeThumbnails: [0]
    }
  },
  mounted() {
    if (!this.$route.params.taskId) {
      this.error('链接地址无效')
      return
    }
    this.taskId = this.$route.params.taskId
    this.$refs.pageContainer.addEventListener('scroll', this.handleScroll)
    this.$http({
      url: this.$http.adornUrl('api/web/info'),
      method: 'get',
      params: this.$http.adornParams({
        token: Vue.cookie.get('token'),
        taskId: this.taskId
      })
    }).then((response) => {
      var res = response.data

      // 获取服务端时间
      this.curDate = new Date(response.headers.date)

      // 当前时间倒计时
      this.timerClock()

      if (res.code === 0) {
        var data = res.data
        this.batch = data.batch
        this.docType = data.docType
        this.task = data.task
        this.system = data.task.system
        this.clientName = data.task.clientName
        if(data.task.clientPic) {
          this.clientPic = data.task.clientPic
        }
        this.userOpenId = data.task.signerOpenId
        this.fileHashEncrypted = data.task.fileHashEncrypted
        this.userStamp = data.task.userSignature
        this.companyStamp = data.task.companySeal
        this.legalStamp = data.task.legalSeal
        this.pageLeftSeconds = data.task.leftSeconds
        this.mobile = data.task.mobile
        this.contentLoading = false
        if (data.token) {
          this.$cookie.set('token', data.token)
        }
        if (this.batch) {
          this.docs = data.docs
          this.fileHash = data.task.fileHash
        } else {
          this.files = data.files
          this.positions = data.positions
          this.loadFiles()
        }
        this.init()
      } else {
        this.error(res.msg)
      }
    }).catch((e) => {
      console.log(e)
      this.error('数据加载失败')
    })
  },
  computed: {
    encrptedMobile() {
      return this.encrptMobile(this.mobile)
    },
    timeStr() {
      if (this.curDate == null) {
        return '00:00:00'
      }
      var curDate = this.curDate
      return curDate.getFullYear() + '-' + this.padLeft(curDate.getMonth() + 1) + '-' + this.padLeft(curDate.getDate()) + ' ' + this.padLeft(curDate.getHours()) + ':' + this.padLeft(curDate.getMinutes()) + ':' + this.padLeft(curDate.getSeconds())
    },
    pageCountdownStr() {
      var seconds = this.pageLeftSeconds
      var m = Math.floor(seconds / 60)
      var s = seconds % 60
      return '00:' + this.padLeft(m) + ':' + this.padLeft(s)
    },
    smsCodeCountdownStr() {
      return this.smsLeftSeconds + 's'
    },
    qrcodeUrl() {
      if (this.taskId && this.fileHash) {
        return 'KZB:2:' + this.taskId + ':' + this.userOpenId + ':' + this.fileHash + ':' + this.fileHashEncrypted
      }
      return ''
    }
  },
  created() {
  },
  methods: {
    init() {
      if (!this.signed) {
        if (this.task.signMethod === 2) {
          if (this.smsLeftSeconds > 0) {
            this.smsCodeCountdown(this.smsLeftSeconds)
          }
        }
        this.pageCountdown()
      }
    },
    // 处理pdf翻页
    handleScroll(e) {
      if (this.pageViews.length === 0) {
        this.pageViews = document.getElementsByClassName('page-view')
      }
      var box = this.pageViews
      var visibleCenter = e.target.scrollTop + e.target.clientHeight / 2
      for (let i = 0; i < box.length; i++) {
        var offsetTop = box[i].offsetTop
        var offsetBottom = box[i].offsetTop + box[i].offsetHeight
        if (offsetTop <= visibleCenter && offsetBottom >= visibleCenter) {
          this.activePage = box[i].firstChild.id
        }
      }
    },
    hasSign(fileIndex, pageIndex) {
      var found = false
      for (var i = 0; i < this.positions.length; i++) {
        var item = this.positions[i]
        if (item.fileIndex === fileIndex && item.pageIndex === pageIndex) {
          found = true
          break
        }
      }
      return found
    },
    // 点击右侧略缩图
    clickThumb(id) {
      this.activePage = id
      var offsetTop = document.getElementById(id).offsetTop - 15 // 70 = 头部的高度 + 间隙的高度
      this.$refs.pageContainer.scrollTop = offsetTop
    },

    // 发送手机验证码
    sendSmsCode() {
      this.$http({
        url: this.$http.adornUrl('api/web/sms'),
        method: 'post',
        data: this.$http.adornData({
          taskId: this.taskId
        })
      }).then(({data}) => {
        if (data.code === 0) {
          this.smsCodeCountdown(data.data.seconds);
        } else {
          this.$message.error(data.msg)
        }
      }).catch((e) => {
        this.$message.error('系统异常，请稍后重试')
      })
    },
    // 获取验证码倒计时1分钟
    smsCodeCountdown(n) {
      this.smsLeftSeconds = n
      this.smsCodeTimer = setInterval(() => {
        this.smsLeftSeconds--
        if (this.smsLeftSeconds <= 0) {
          this.smsBtnText = '重新获取'
          clearInterval(this.smsCodeTimer)
        }
      }, 1000)
    },
    // 页面倒计时 5分钟
    pageCountdown() {
      this.pageTimer = setInterval(() => {
        this.pageLeftSeconds--
        if (this.pageLeftSeconds <= 0) {
          clearInterval(this.pageTimer)
          clearInterval(this.smsCodeTimer)
          this.statusDialogVisible = true
          this.toErrorPage()
        }
      }, 1000)
    },
    toErrorPage() {
      this.error('未在规定的时间内完成签署，请关闭当前页面后重试')
    },
    timerClock() {
      setInterval(() => {
        this.curDate = new Date(this.curDate.getTime() + 1000)
      }, 1000)
    },
    padLeft(val) {
      if (val < 10) {
        return '0' + val
      }
      return '' + val
    },
    // 提交签署按钮
    submit(event) {
      event.currentTarget.blur();
      event.preventDefault();

      if (this.task.signMethod == 1) {
        // 即时公证签署码
        if (this.smsCode === '') {
          this.$message.error('请输入验证码')
          return false
        }
        var formData = {
          taskId: this.taskId,
          smsCode: this.smsCode
        }
      } else if (this.task.signMethod == 2) {
        // 短信验证码
        if (this.smsCode === '') {
          this.$message.error('请输入验证码')
          return false
        }
        var formData = {
          taskId: this.taskId,
          smsCode: this.getSign(this.taskId, this.smsCode)
        }
      } else if (this.task.signMethod == 4) {
        // 签署密码
        if (this.smsCode === '') {
          this.$message.error('请输入签署密码')
          return false
        }
        var formData = {
          taskId: this.taskId,
          signature: airsign.sign(this.fileHash, this.smsCode, this.task.signerIdNumber)
        }
      }

      this.btnLoading = true
      this.$http({
        url: this.$http.adornUrl('api/web/confirm'),
        method: 'post',
        data: this.$http.adornData(formData)
      }).then(({data}) => {
        this.btnLoading = false
        if (data.code === 0) {
          this.statusDialogVisible = true
          this.status = 'success'
        } else {
          this.$message.error(data.msg)
        }
      }).catch((e) => {
        this.btnLoading = false
        this.$message.error('系统异常，请稍后重试')
      })
    },
    reject() {
      this.$confirm('拒签后当前任务无法继续流转、签署，请确认是否拒签？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.contentLoading = true
        this.$http({
          url: this.$http.adornUrl('api/web/reject'),
          method: 'post',
          data: this.$http.adornData({
            taskId: this.taskId
          })
        }).then(({data}) => {
          this.contentLoading = false
          if (data && data.code === 0) {
            this.statusDialogVisible = true
            this.status = 'reject'
          } else {
            this.$message.error(data.msg);
          }
        }).catch((e) => {
          this.contentLoading = false
          this.$message.error('系统异常，请稍后重试')
        })
      });
    },
    // 立即签署按钮
    momentSign() {
      this.momentSignDialogVisible = true
    },
    // 关闭即时公证码弹窗时清空验证码
    momentClose() {
      this.smsCode = ''
    },
    // 短信签署按钮
    messageSign() {
      this.messageSignDialogVisible = true
      if (this.smsLeftSeconds <= 0) {
        this.sendSmsCode()
      }
    },
    airSign() {
      this.airSignDialogVisible = true
    },
    // 关闭短信弹窗时清空验证码
    messageClose() {
      this.smsCode = ''
    },
    handlerClose() {
      this.toCallBackUrl()
    },
    toCallBackUrl(type) {
      var callbackUrl = this.task.callbackUrl
      if (callbackUrl) {
        var ss = callbackUrl.split('#'), hash = ''
        callbackUrl = ss[0]
        if (ss.length > 1) hash = ss[1]
        if (callbackUrl.indexOf('?') > -1) {
          callbackUrl += '&type=' + type
        } else {
          callbackUrl += '?type=' + type
        }
        callbackUrl += hash.length > 0 ? ('#' + hash) : ''
        location.replace(callbackUrl)
      } else {
        if (history.length > 1) {
          history.back()
        } else {
          window.close()
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.gz-wrap {
  /*background-color: #CB2828;*/ /*#CBCBCB*/
  background-color: #CBCBCB; /*#CBCBCB*/
  /*min-width: 1024px;*/
  width: 100%;
  height: 100%;

/*  padding-left: 10px;
  padding-right: 10px;*/
 /* max-width: 820px;*/
  min-width: 400px;

  position: relative;
  display: flex;
}

.gz-main {
  position:relative;
  width: 100%;
  background-color: #CBCBCB;
  overflow-y: auto;
  flex: 1;
  padding: 15px;

  .doc-list-wrap {
    width: 820px;
    height: 100%;
    margin: 0 auto;
    overflow-y: auto;
    padding: 30px;
    background: #FFF;

    .tips {
      line-height: 2;
      text-align: center;
      margin-bottom: 30px;

      .highlight {
        color: #F7BA2A;
      }
    }

    .doc-list {
      .doc-item {
        display: flex;
        border: 1px solid #EFEFEF;
        border-top: none;

        &:first-child {
          border-top: 1px solid #EFEFEF;
        }

        & > div {
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding: 10px;
        }

        .doc-index {
          width: 60px;
          text-align: center;
        }

        .doc-title {
          flex: 1;
        }

        .doc-partner {
          flex: 1;

          .icon-svg {
            position: relative;
            top: 2px;
          }
        }

        .doc-action {
          width: 60px;
        }
      }

      .doc-head {
        height: 50px;
        font-weight: bold;
      }
    }
  }

  .file-wrapper {
    max-width: 820px;
    width: 100%;
    margin: 0 auto;

    .page-view {
      margin-bottom: 12px;

      .paper {
        width: 100%;
        background-color: #ffffff;
        position: relative;
        .textLayer {
          //background-color: #10aeff;
          position: absolute;
          left: 0;
          top: 0;
          //width: 100%;
          //height: 100%;
          font-size: 0;
          /*z-index: 99999999;*/
          /*pointer-events: none;*/
        }
        .paper-cover {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;

          .img-stamp {
            position: absolute;
            -webkit-filter: grayscale(1); /* Webkit */
            filter: gray; /* IE6-9 */
            filter: grayscale(1); /* W3C */
          }

          .sign-text {
            position: absolute;
            /*font-weight: 900;*/
          }
        }
      }

      .paper-bottom {
        position: relative;
        text-align: center;
        font-size: 12px;
        margin-top: 5px;
        color: #333333;

        .doc-name {
          float: left;
        }
      }
    }
  }
}

.gz-left {
  width: 200px;
  background-color: #CBCBCB;
}

.gz-right {
  width: 200px;
  background-color: #ffffff;
  overflow-y: scroll;
  overflow-x: hidden;
  position: relative;

  .file-page-wrap {
    padding-left: 10px;
    position: relative;
    z-index: 9;

    .file-title {
      font-size: 14px;
      width: 150px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      .icon-svg {
        position: absolute;
        right: 10px;
        top: 12px;
        font-size: 12px;
        cursor: pointer;
      }
    }

    .page-wrap {
      margin-bottom: 10px;
      padding-left: 15px;
      width: 138px;

      .page-item {
        position: relative;
        border: 1px solid #999999;
        /*height: 190px;*/
        margin-bottom: 15px;

        &.current {
          border-color: #0b76f7;

          .page-corner {
            color: #0b76f7;
          }
        }

        .page-corner {
          position: absolute;
          bottom: -1px;
          left: -1px;
          font-style: inherit;
          color: #999999;
          font-size: 38px;

        }

        b {
          font-size: 12px;
          color: #ffffff;
          position: absolute;
          bottom: 0;
          left: 3px;
          font-weight: 400;
        }

        .page-mark {
          color: #ffae00;
          font-size: 20px;
          position: absolute;
          top: -1px;
          right: 10px;
        }

        img {
          width: 138px;
          height: 190px;
        }

        .paper-thumb {
          width: 100%;
        }
      }
    }
  }
}

.gz-bottom {
  box-shadow: 0 -3px 10px #7E7E7E;
  background: #FFF;
  text-align: center;
  position: fixed;
  width: 100%;
  height: 80px;
  bottom: 35px;
  left: 0;

  .gz-valid {
    /*width: 800px;*/
    width: 100%;
    margin: 0 auto;
    display: flex;
    height: 80px;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .gz-valid-tips {
      font-size: 12px;
      margin: 12px 0 8px;
      line-height: 1.15;
      color: #333333;
    }

    .gzbtn-fr {
      width: 88px;
      height: 36px;
      vertical-align: top;
      border-radius: 4px;
      /*float: right;*/
      background-color: #FFFFFF;
      border: 1px solid #dcdfe6;
      color: #606266;
      font-size: 14px;
      padding: 0;
    }

    .valid-info {
      padding: 8px 0;
      font-size: 12px;
      line-height: 1.15;
      color: #333;
      margin: 0;

      span {
        color: #428BCA;
      }
    }

    .input-box {
      width: 253px;
      position: relative;
      display: inline-block;
      height: 36px;

      .valid-input {
        width: 100%;
        border: 1px solid #BFCBD9;
        border-radius: 3px;
        height: 100%;
        padding-left: 10px;
      }

      .gzbtn-valid {
        position: absolute;
        right: 0;
        top: 0;
        border-radius: 0 3px 3px 0;
        height: 36px;
        background-color: #495667;
        color: #FFFFFF;
        border: 1px solid #495667;
        width: 82px;
        cursor: pointer;
      }
    }

    .gzbtn-send {
      width: 88px;
      color: #FFFFFF;
      background-color: #FFAE00;
      height: 36px;
      border: 0 none;
      vertical-align: top;
      border-radius: 4px;
      margin-left: 5px;
    }
  }

  .gzbtn-close {
    background-color: #FFFFFF;
    border: 1px solid #BFCBD9;
    color: #BFCBD9;
    font-size: 14px;
    width: 88px;
    height: 35px;
    margin-top: 23px;
  }
}

.gz-foot {
  height: 35px;
  line-height: 35px;
  text-align: center;
  color: #666666;
  background: #FFF;
  border-top: 1px solid #ddd;
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
}

.sign-index {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /*min-width: 1024px;*/
  box-sizing: border-box;
  padding-top: 53px;
  padding-bottom: 116px;
}

.status-dialog-content {
  text-align: center;

  img {
    margin-bottom: 30px;
  }

  span {
    line-height: 1.5;
  }
}

.gz-head {
  height: 53px;
  background-color: #2B1C05;
  text-align: center;
  line-height: 53px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;

  .gz-head-inner {
    padding: 0 15px;
    margin: 0 auto;
  }

  .time-area {
    color: #F7BA2A;
    text-align: left;
    width: 200px;
    position: absolute;
    left: 20px;
    top: 0;

    .clock {
      height: 54px;
      line-height: 54px;
      font-size: 14px;
    }
  }

  .counter {
    height: 39px;
    line-height: 39px;
    font-size: 16px;
    position: absolute;
    left: 50%;
    top: 46px;
    color: #F7BA2A;
    width: 219px;
    text-align: center;
    transform: translateX(-50%);
  }

  .ssgz {
    font-size: 16px;
    color: #ffffff;
    display: inline-block;
  }

  .gzfw {
    color: #F7BA2A;
    font-size: 14px;
    position: absolute;
    right: 20px;
    top: 0;
  }
}

.qrcode-dialog-content {
  text-align: center;
  margin: -25px 0;

  .top-tips {
    line-height: 1.5;
    color: #000;
    margin-bottom: 20px;
  }

  .bottom-tips {
    line-height: 1.5;
    color: #666;
    margin-top: 15px;
  }

  .icon-svg__close {
    font-size: 32px;
    position: absolute;
    bottom: -60px;
    left: 50%;
    transform: translateX(-50%);
    color: #FFF;
    cursor: pointer;
  }
}

.qrcode-wrap {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.qrcode-ct {
  position: relative;
  width: 140px;
  height: 140px;
  display: inline-block;
}

.pop-title {
  text-align: center;
  height: 66px;

  .logo {
    height: 100%;
  }
}

.pop-flex {
  display: flex;
  flex-direction: row;

  .pop-flex-wrap {
    flex: 1;
    background-color: #F3F5F7;
    margin-left: 2px;
    margin-right: 2px;
    padding: 10px;
  }
}

.pop-form {
  padding: 5px 30px;
  .pop-input-yzm {
    display: flex;
    flex-direction: row;
    .el-button {
      width: 110px;
      margin-left: 10px;
    }
    .forget-pass {
      width: 120px;
      height: 40px;
    }
  }
}
</style>
