<template>
  <div class="sign-index" v-loading="contentLoading">
      <div class="gz-head">
        <div class="gz-head-inner">
          <div class="time-area">
            <div class="clock">{{timeStr}}</div>
          </div>
          <div class="ssgz">{{title}}</div>
          <div class="gzfw">本次任务由{{deptName?deptName:'南京公证处'}}为您提供公证服务</div>
        </div>
      </div>
      <div class="gz-wrap clearfix">
        <div class="gz-left"></div>
        <div ref="pageContainer" class="gz-main">
          <div class="file-wrapper">
          <div class="file-view" v-for="(file, fileIndex) in files">
            <div class="page-view" v-for="(page, pageIndex) in file.filePage">
              <div class="paper" :id="fileIndex + '-' + pageIndex" v-loading="!loading[fileIndex + '-' + pageIndex]">
                <canvas class="paper-pdf" :id="'pdf-' + fileIndex + '-' + pageIndex"></canvas>
              </div>
              <div class="paper-bottom clearfix">
                <span class="doc-index">- {{page}}/{{file.filePage}} -</span><span class="doc-name">{{file.fileName}}</span>
              </div>
            </div>
          </div>
          </div>
        </div>
        <div class="gz-right">
          <el-collapse v-model="activeThumbnails">
            <el-collapse-item :name="fileIndex" class="file-page-wrap" v-for="(file, fileIndex) in files" :key="'thumb_'+fileIndex">
              <template slot="title">
                <div class="file-title">{{file.fileName}}</div>
              </template>
              <div class="page-wrap">
                <div @click="clickThumb(fileIndex + '-' + pageIndex)" class="page-item" :class="{current: activePage == (fileIndex + '-' + pageIndex)}" v-for="(page, pageIndex) in file.filePage" :key="fileIndex + '-' + pageIndex" v-loading="!loading[fileIndex + '-' + pageIndex]">
                  <canvas :id="'thumb-' + fileIndex + '-' + pageIndex" class="paper-thumb"></canvas>
                  <icon-svg name="corner" class="page-corner"></icon-svg><b>{{page}}</b>
                </div>
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>
      </div>
      <div class="gz-foot">契约互联电子公证服务</div>
  </div>
</template>

<script>
  import Vue from 'vue'
  import qrcode from '@xkeshi/vue-qrcode'
  import PDFLoader from '../../mixins/pdf-loader'
  import Base from '../../mixins/base'
  import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry'
  const PDFJS = require("pdfjs-dist")
  PDFJS.GlobalWorkerOptions.workerSrc = pdfjsWorker
  PDFJS.GlobalWorkerOptions.cMapUrl = process.env.BASE_URL + 'static/cmaps/'
  PDFJS.GlobalWorkerOptions.cMapPacked = true

  export default {
    components: { qrcode },
    mixins: [PDFLoader, Base],
    data () {
      return {
        token: null,
        docId: '',
        pageViews: [],
        loading: {},
        ws: {},
        fs: {},
        contentLoading: true,
        title: '',
        deptName: '',
        files: [],
        positions: {},
        activePage: '0-0',
        curDate: null,
        activeThumbnails: [0]
      }
    },
    mounted () {
      this.token = this.$route.params.token
      this.docId = this.$route.params.docId

      if (!this.token) {
        this.error('链接地址无效')
        return
      }

      this.$refs.pageContainer.addEventListener('scroll', this.handleScroll)
      this.$http({
        url: this.$http.adornUrl('api/web/preview'),
        method: 'get',
        params: this.$http.adornParams({
          token: this.token,
          docId: this.docId
        })
      }).then((response) => {
        var res = response.data

        // 获取服务端时间
        this.curDate = new Date(response.headers.date)

        // 当前时间倒计时
        this.timerClock()

        if (res.code === 0) {
          var data = res.data
          this.title = data.title
          this.deptName = data.deptName
          this.contentLoading = false
          this.files = data.files
          this.positions = data.positions
          this.loadFiles()
        } else {
            this.error(res.msg)
        }
      }).catch((e) => {
          this.error('数据加载失败')
      })
    },
    computed: {
      timeStr () {
        if (this.curDate == null) {
          return '00:00:00'
        }
        var curDate = this.curDate
        return curDate.getFullYear() + '-' + this.padLeft(curDate.getMonth() + 1) + '-' + this.padLeft(curDate.getDate()) + ' ' + this.padLeft(curDate.getHours()) + ':' + this.padLeft(curDate.getMinutes()) + ':' + this.padLeft(curDate.getSeconds())
      }
    },
    created () {
    },
    methods: {
      // 处理pdf翻页
      handleScroll (e) {
        if (this.pageViews.length === 0) {
          this.pageViews = document.getElementsByClassName('page-view')
        }
        var box = this.pageViews
        var visibleCenter = e.target.scrollTop + e.target.clientHeight / 2
        for (let i = 0; i < box.length; i++) {
          var offsetTop = box[i].offsetTop
          var offsetBottom = box[i].offsetTop + box[i].offsetHeight
          if (offsetTop <= visibleCenter && offsetBottom >= visibleCenter) {
            this.activePage = box[i].firstChild.id
          }
        }
      },
      hasSign (fileIndex, pageIndex) {
        var found = false
        for (var i = 0; i < this.positions.length; i++) {
          var item = this.positions[i]
          if (item.fileIndex === fileIndex && item.pageIndex === pageIndex) {
            found = true
            break
          }
        }
        return found
      },
      // 点击右侧略缩图
      clickThumb (id) {
        this.activePage = id
        var offsetTop = document.getElementById(id).offsetTop - 15 // 70 = 头部的高度 + 间隙的高度
        this.$refs.pageContainer.scrollTop = offsetTop
      },
      timerClock () {
        setInterval(() => {
          this.curDate = new Date(this.curDate.getTime() + 1000)
        }, 1000)
      },
      padLeft (val) {
        if (val < 10) {
          return '0' + val
        }
        return '' + val
      }
    }
  }
</script>

<style lang="scss" scoped>
  .gz-wrap {
    background-color: #CBCBCB;
    /*min-width: 1024px;*/
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
  }

  .gz-main {
    background-color: #CBCBCB;
    overflow-y: auto;
    flex: 1;
    padding: 15px;
    .doc-list-wrap{
      width: 820px;
      height: 100%;
      margin: 0 auto;
      overflow-y: auto;
      padding: 30px;
      background: #FFF;
      .doc-list{
        .doc-item{
          display: flex;
          border: 1px solid #EFEFEF;
          border-top: none;
          &:first-child{
            border-top: 1px solid #EFEFEF;
          }
          &>div{
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 10px;
          }
          .doc-title{
            flex: 1;
          }
          .doc-status{
            width: 100px;
            .el-tag{
              width: auto;
            }
          }
          .doc-partner{
            flex: 1;
          }
          .doc-file{
            flex: 1;
            .file-name{
              line-height: 1.6;
            }
          }
        }
      }
    }
    .file-wrapper{
      width: 820px;
      margin: 0 auto;
      .page-view{
        margin-bottom: 12px;
        .paper{
          width:100%;
          background-color: #ffffff;
          position: relative;
          .paper-cover{
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            .img-stamp{
              position: absolute;
              -webkit-filter: grayscale(1); /* Webkit */
              filter: gray; /* IE6-9 */
              filter: grayscale(1); /* W3C */
            }
            .sign-date{
              position: absolute;
              font-weight: 900;
            }
          }
        }
        .paper-bottom{
          position: relative;
          text-align: center;
          font-size: 12px;
          margin-top: 5px;
          color: #333333;
          .doc-name{
            float: left;
          }
        }
      }
    }
  }
  .gz-left {
    width:200px;
    background-color: #CBCBCB;
  }
  .gz-right {
    width:200px;
    background-color: #ffffff;
    overflow-y: scroll;
    overflow-x: hidden;
    position: relative;
    .file-page-wrap{
      padding-left:10px;
      position: relative;
      z-index: 9;
      .file-title {
        font-size: 14px;
        width:150px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space:nowrap;
        .icon-svg{
          position: absolute;
          right: 10px;
          top: 12px;
          font-size: 12px;
          cursor: pointer;
        }
      }
      .page-wrap{
        margin-bottom: 10px;
        padding-left: 15px;
        width:138px;
        .page-item{
          position: relative;
          border: 1px solid #999999;
          /*height: 190px;*/
          margin-bottom: 15px;
          &.current{
            border-color: #0b76f7;
            .page-corner{
              color: #0b76f7;
            }
          }
          .page-corner{
            position: absolute;
            bottom: -1px;
            left: -1px;
            font-style: inherit;
            color: #999999;
            font-size: 38px;

          }
          b{
            font-size: 12px;
            color: #ffffff;
            position: absolute;
            bottom: 0;
            left: 3px;
            font-weight: 400;
          }
          .page-mark{
            color: #ffae00;
            font-size: 20px;
            position: absolute;
            top:-1px;
            right:10px;
          }
          img{
            width:138px;
            height:190px;
          }
          .paper-thumb{
            width: 100%;
          }
        }
      }
    }
  }

  .gz-bottom {
    box-shadow: 0 -3px 10px #7E7E7E;
    background: #FFF;
    text-align: center;
    position: fixed;
    width: 100%;
    height: 80px;
    bottom: 35px;
    left: 0;
    .gz-valid {
      width: 800px;
      margin: 0 auto;
      display: flex;
      height: 80px;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      .gz-valid-tips{
        font-size: 12px;
        margin: 12px 0 8px;
        line-height: 1.15;
        color: #333333;
      }
      .gzbtn-fr {
        width: 88px;
        height: 36px;
        vertical-align: top;
        border-radius: 4px;
        /*float: right;*/
        background-color: #FFFFFF;
        border: 1px solid #dcdfe6;
        color: #606266;
        font-size: 14px;
        padding: 0;
      }
      .valid-info {
        padding: 8px 0;
        font-size: 12px;
        line-height: 1.15;
        color: #333;
        margin: 0;
        span {
          color: #428BCA;
        }
      }
      .input-box {
        width: 253px;
        position: relative;
        display: inline-block;
        height: 36px;
        .valid-input {
          width: 100%;
          border: 1px solid #BFCBD9;
          border-radius: 3px;
          height: 100%;
          padding-left: 10px;
        }
        .gzbtn-valid {
          position: absolute;
          right: 0;
          top: 0;
          border-radius: 0 3px 3px 0;
          height: 36px;
          background-color: #495667;
          color: #FFFFFF;
          border: 1px solid #495667;
          width: 82px;
          cursor: pointer;
        }
      }
      .gzbtn-send {
        width: 88px;
        color: #FFFFFF;
        background-color: #FFAE00;
        height: 36px;
        border: 0 none;
        vertical-align: top;
        border-radius: 4px;
        margin-left: 5px;
      }
    }
    .gzbtn-close{
      background-color: #FFFFFF;
      border: 1px solid #BFCBD9;
      color: #BFCBD9;
      font-size: 14px;
      width: 88px;
      height: 35px;
      margin-top: 23px;
    }
  }

  .gz-foot {
    height: 35px;
    line-height: 35px;
    text-align: center;
    color: #666666;
    background: #FFF;
    border-top: 1px solid #ddd;
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
  }
  .sign-index{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /*min-width: 1024px;*/
    box-sizing: border-box;
    padding-top: 53px;
    padding-bottom: 35px;
  }
  .status-dialog-content{
    text-align: center;
    img{
      margin-bottom: 30px;
    }
    span{
      line-height: 1.5;
    }
  }
  .gz-head{
    height: 53px;
    background-color: #2B1C05;
    text-align: center;
    line-height: 53px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;
    .gz-head-inner{
      padding: 0 15px;
      margin: 0 auto;
    }
    .time-area{
      color: #F7BA2A;
      text-align: left;
      width: 200px;
      position: absolute;
      left: 20px;
      top: 0;
      .clock{
        height: 54px;
        line-height: 54px;
        font-size: 14px;
      }
    }
    .counter{
      height: 39px;
      line-height: 39px;
      font-size: 16px;
      position: absolute;
      left: 50%;
      top: 46px;
      color: #F7BA2A;
      width: 219px;
      text-align: center;
      transform: translateX(-50%);
    }
    .ssgz{
      font-size: 16px;
      color: #ffffff;
      display: inline-block;
    }
    .gzfw{
      color: #F7BA2A;
      font-size: 14px;
      position: absolute;
      right: 20px;
      top: 0;
    }
  }
  .qrcode-dialog-content{
    text-align: center;
    margin: -25px 0;
    .top-tips{
      line-height: 1.5;
      color: #000;
      margin-bottom: 20px;
    }
    .bottom-tips{
      line-height: 1.5;
      color: #666;
      margin-top: 15px;
    }
    .icon-svg__close{
      font-size: 32px;
      position: absolute;
      bottom: -60px;
      left: 50%;
      transform: translateX(-50%);
      color: #FFF;
      cursor: pointer;
    }
  }
  .qrcode-wrap{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .pop-title{
    text-align: center;
    .logo{
      width: 180px;
    }
  }
  .pop-flex{
    display: flex;
    flex-direction: row;
    .pop-flex-wrap{
      flex: 1;
      background-color: #F3F5F7;
      margin-left: 2px;
      margin-right: 2px;
      padding-top: 15px;
      padding-bottom: 15px;
    }
  }
  .pop-form{
    padding: 20px 30px;
    .pop-input-yzm{
      display: flex;
      flex-direction: row;
      .el-button{
        width: 110px;
        margin-left: 10px;
      }
    }
  }
  @media screen and (max-width: 1024px) {
    .sign-index{
      padding-top: 40px;
    }
    .gz-head {
      height: 40px;
      line-height: 40px;
      font-size: 14px;
      .time-area, .gzfw{
        display: none;
      }
    }
    .gz-main{
      padding: 10px;
      .file-wrapper{
        width: 100%;
      }
      .paper-bottom{
        font-size: 10px;
      }
    }
  }
</style>
