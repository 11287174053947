function fb(bits){
  return sjcl.codec.hex.fromBits(bits)
}

function pbkdf2(password, salt){
  var seed = sjcl.hash.sha256.hash(password);
  var salt = sjcl.hash.sha256.hash(password + salt);
  var password_kdf_bit = sjcl.misc.pbkdf2(seed, salt);
  return fb(password_kdf_bit)
}

function generateKeypair(password, salt){
  var secret_key = pbkdf2(password, salt)
  return sjcl.ecc.ecdsa.generateKeys(sjcl.ecc.curves.k256, 0, new sjcl.bn(secret_key));
}

function sign(msg, password, salt){
  var keys = generateKeypair(password, salt);
  var msg_hash = sjcl.hash.sha256.hash(msg);
  var signature = keys.sec.sign(msg_hash);
  return fb(signature)
}

export default {
  pbkdf2,
  generateKeypair,
  sign
}
