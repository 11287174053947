<template>
  <div class="site-wrapper site-page--not-found">
    <div class="site-content__wrapper">
      <div class="site-content">
        <img :src="errorPng" alt="" style="width: 200px;">
        <p class="not-found-desc">{{msg}}</p>
        <el-button @click="goback">返回上一页</el-button>
        <!--<el-button type="primary" class="not-found-btn-gohome" @click="$router.push({ name: 'home' })">关闭页面</el-button>-->
      </div>
    </div>
  </div>
</template>

<script>
  import Base from '../../mixins/base'

  export default {
    mixins: [Base],
    data () {
      return {
        msg: '页面已失效，请返回上一页后重试',
        errorPng: 'static/images/error.png'
      }
    },
    mounted() {
      if (this.$route.query.msg) {
        this.msg = this.$route.query.msg
      }
    },
    methods: {
    }
  }
</script>

<style lang="scss">
  .site-wrapper.site-page--not-found {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    .site-content__wrapper {
      padding: 0;
      margin: 0;
      background-color: #fff;
    }
    .site-content {
      position: fixed;
      top: 15%;
      left: 50%;
      z-index: 2;
      padding: 30px;
      text-align: center;
      transform: translate(-50%, 0);
    }
    .not-found-title {
      margin: 20px 0 15px;
      font-size: 10em;
      font-weight: 400;
      color: rgb(55, 71, 79);
    }
    .not-found-desc {
      margin: 30px 0 50px;
      font-size: 24px;
      text-transform: uppercase;
      color: rgb(118, 131, 143);
      > em {
        font-style: normal;
        color: #ee8145;
      }
    }
    .not-found-btn-gohome {
      margin-left: 30px;
      color: #FFFFFF;
      background-color: #FFAE00;
      border: none;
    }
  }
</style>
