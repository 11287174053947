<template>
  <div class="sign-index-h5" v-loading="contentLoading" :style="'min-height:' + windowHeight + 'px;'">
    <header id="header" class="mui-bar mui-bar-nav">
      <a class="mui-action-back mui-icon mui-icon-left-nav mui-pull-left" @click="pageback"><icon-svg name="back"></icon-svg></a>
      <h1 class="mui-title">{{title}}</h1>
    </header>
    <div class="header">
        您已进入电子公证系统，您的操作将被实时公证记录
      </div>
      <div ref="pageContainer" class="content">
        <div class="file-wrapper">
          <div class="file-view" v-for="(file, fileIndex) in files">
            <div class="page-view" v-for="(page, pageIndex) in file.filePage">
              <div class="paper" :id="fileIndex + '-' + pageIndex" v-loading="!loading[fileIndex + '-' + pageIndex]">
                <canvas class="paper-pdf" :id="'pdf-' + fileIndex + '-' + pageIndex"></canvas>
                <div class="paper-cover" :data-page="page" :data-file_id="file.file_id">
                  <template  v-for="sPosition in positions">
                    <template v-if="sPosition.fileIndex === fileIndex && sPosition.pageIndex === pageIndex">
                      <template v-if="sPosition.type ==='seal'">
                        <img class="img-stamp" :src="companyStamp" :style="'top:' + sPosition.y * 100 + '%;left:' + sPosition.x * 100 + '%;width:'+ sPosition.w * 100 + '%;'"/>
                      </template>
                      <template v-if="userStamp && sPosition.type ==='signature'">
                        <img class="img-stamp" :src="userStamp" :style="'top:' + sPosition.y * 100 + '%;left:' + sPosition.x * 100 + '%;width:'+ sPosition.w * 100 + '%;'"/>
                      </template>
                      <template v-if="sPosition.type ==='text'">
                        <div class="sign-text" :style="'top:' + sPosition.y * 100 + '%;left:' + sPosition.x * 100 + '%;font-size:' + sPosition.fontSize * fontScale + 'px;'">{{sPosition.content}}</div>
                      </template>
                    </template>
                  </template>
                </div>
              </div>
              <div class="paper-bottom clearfix">
                <span class="doc-index">- {{page}}/{{file.filePage}} -</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer" v-if="!signed">
        <div v-if="disabled" class="sign-tips">签署合同之前，请先浏览完合同内容</div>
        <el-button class="btn-sign" type="primary" :disabled="disabled" @click="signNow" :loading="btnLoading">立即签署</el-button>
        <el-button v-if="task.rejectionSupport" class="btn-sign" type="warning" :disabled="disabled" @click="reject">拒绝签署</el-button>
      </div>
      <div v-if="!signed" class="page-countdown">{{pageCountdownStr}}</div>
      <el-button v-show="false" class="file-select" type="primary" size="mini" @click="fileSelectDrawerVisible = true"><icon-svg name="link"></icon-svg> 附件</el-button>
      <el-drawer
              title="文件列表"
              :visible.sync="fileSelectDrawerVisible"
              :show-close="false"
              class="file-select-drawer"
              size="60%"
              direction="rtl">
        <div class="file-list">
          <div class="file-item" v-for="(file, fileIndex) in task.files" @click="selectFile(fileIndex)">{{file.fileName}}</div>
        </div>
      </el-drawer>

      <el-drawer
            :visible.sync="handwrittenDialogVisible"
            direction="btt"
            custom-class="handwritten-dialog"
            size="auto"
            :show-close="false">
          <handwritten-view @useSignature="useSignature"></handwritten-view>
      </el-drawer>

      <el-drawer
        :visible.sync="signDialogVisible"
        direction="btt"
        custom-class="sign-dialog-h5"
        size="auto"
        :before-close="signDialogBeforeClose"
        :show-close="false">
        <div slot="title" class="dialog-header">
          <img class="logo" :src="baseUrl + 'static/images/logo.png'" onerror="this.parentElement.style.display='none';" />
          <div class="btn-getcode">
            <template v-if="task.signMethod === 1">
              <!--即时公证码-->
              <el-button type="primary" size="mini" :loading="getCodeBtnLoading" @click="showQrcodeDialog">获取验证码</el-button>
            </template>
            <template v-if="task.signMethod === 2">
              <!--短信验证码-->
              <template v-if="smsLeftSeconds <= 0">
                <el-button type="primary" size="mini" @click="sendSmsCode"><icon-svg name="refresh"></icon-svg>重新获取</el-button>
              </template>
              <el-button v-else type="primary" size="mini">{{smsCodeCountdownStr}}</el-button>
            </template>
          </div>
        </div>
        <div class="dialog-body">
          <div class="mobile-tips">公证验证码已发送至{{encrptedMobile}}</div>
          <el-input @blur="inputBlur" v-model="smsCode" placeholder="输入合同签署验证码"></el-input>
          <div class="tips">输入验证码并提交，即表明您自愿签署本文件，您将接受文件条款的约束力</div>
        </div>
        <div class="dialog-footer">
          <el-button  @click="cancel">取 消</el-button>
          <el-button type="primary" :loading="btnLoading" @click="confirm">确 定</el-button>
        </div>
      </el-drawer>
  </div>
</template>

<script>
  import Vue from 'vue'
  import qrcode from '@xkeshi/vue-qrcode'
  import PDFLoader from '../../mixins/pdf-loader'
  import Base from '../../mixins/base'
  import HandwrittenView from '@/components/handwritten'
  import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry'
  const PDFJS = require("pdfjs-dist")
  PDFJS.GlobalWorkerOptions.workerSrc = pdfjsWorker
  PDFJS.GlobalWorkerOptions.cMapUrl = process.env.BASE_URL + 'static/cmaps/'
  PDFJS.GlobalWorkerOptions.cMapPacked = true
  var initScrollTop;

  export default {
    components: { qrcode ,HandwrittenView},
    mixins: [PDFLoader, Base],
    data () {
      return {
        windowHeight: 0,
        taskId: null,
        pageViews: [],
        pageTimer: null,
        smsCodeTimer: null,
        loading: {},
        contentLoading: true,
        dialogVisible: false,
        signDialogVisible: false,
        handwrittenDialogVisible: false,
        fileSelectDrawerVisible: false,
        showVerify: false,
        getCodeBtnLoading: false,
        btnLoading: false,
        rejectBtnLoading: false,
        successPng: this.baseUrl + 'static/images/success.png',
        failPng: this.baseUrl + 'static/images/fail.png',
        status: '',
        scale: 1.5,
        docObj: [0],
        nextTop: -1,
        activeFile: null,
        activeFilePageNum: 1,
        title: '',
        docType: 0,
        batch: false,
        task: {},
        docs: [],
        files: [],
        positions: {},
        companyStamp: '',
        userStamp: '',
        activePage: '0-0',
        mobile: null,
        system: '',
        userOpenId: '',
        fileHashEncrypted: '',
        handwritten: 0,
        show: true,
        signed: false,
        smsCode: '',
        pageLeftSeconds: 1200,
        smsLeftSeconds: -1,
        curDate: null,
        loadingInstance: null,
        isCheckSign: true,
        activeThumbnails: [0],
        disabled: false
      }
    },
    mounted () {
      this.windowHeight = window.innerHeight
      if (!this.$route.params.taskId) {
        this.error('链接地址无效')
        return
      }

      window.addEventListener('scroll', this.handleScroll)

      this.taskId = this.$route.params.taskId
      this.$http({
        url: this.$http.adornUrl('api/web/info'),
        method: 'get',
        params: this.$http.adornParams({
          token: Vue.cookie.get('token'),
          taskId: this.taskId
        })
      }).then((response) => {
        var res = response.data

        // 获取服务端时间
        this.curDate = new Date(response.headers.date)

        if (res.code === 0) {
          var data = res.data
          this.batch = data.batch
          this.title = data.title
          this.docType = data.docType
          this.task = data.task
          this.system = data.task.system
          this.clientName = data.task.clientName
          this.handwritten = data.task.handwritten
          this.userOpenId = data.task.signerOpenId
          this.fileHashEncrypted = data.task.fileHashEncrypted
          if (!this.handwritten) {
            this.userStamp = data.task.userSignature
          }
          this.companyStamp = data.task.companySeal
          this.pageLeftSeconds = data.task.leftSeconds
          this.mobile = data.task.mobile
          this.contentLoading = false
          if (data.token) {
            this.$cookie.set('token', data.token)
          }
          if (!window.isJsgz) {
            this.task.signMethod = 2
          }
          if (this.batch) {
            this.docs = data.docs
            this.fileHash = data.task.fileHash
          } else {
            this.files = data.files
            this.positions = data.positions
            this.loadFiles()
          }
          this.init()
        } else {
          this.error(res.msg)
        }
      }).catch(() => {
        this.error('数据加载失败')
      })
    },
    computed: {
      encrptedMobile () {
        return this.encrptMobile(this.mobile)
      },
      pageCountdownStr () {
        var seconds = this.pageLeftSeconds
        var m = Math.floor(seconds / 60)
        var s = seconds % 60
        return this.padLeft(m) + ':' + this.padLeft(s)
      },
      smsCodeCountdownStr () {
        return this.smsLeftSeconds + 's'
      },
      qrcodeUrl () {
        if (this.taskId) {
          return 'KZB:2:' + this.taskId + ':' + this.userOpenId + ':' + this.fileHash + ':' + this.fileHashEncrypted
        }
        return 'KZB:2:0:0:0:0'
      }
    },
    methods: {
      init () {
        if (!this.signed) {
          if (this.task.forceView) {
            this.disabled = true
          }
          if (this.task.signMethod === 2) {
            if (this.smsLeftSeconds > 0) {
              this.smsCodeCountdown(this.smsLeftSeconds)
            }
          }
          this.pageCountdown()
        }
      },
      handleScroll (e) {
        let rect = this.$el.getBoundingClientRect()
        if (rect.bottom - window.innerHeight < 50) {
          // 到达底部
          this.disabled = false
        }
        // if (this.pageViews.length === 0) {
        //   this.pageViews = document.getElementsByClassName('page-view')
        // }
        // var box = this.pageViews
        // var visibleCenter = e.target.scrollTop + e.target.clientHeight / 2
        // for (let i = 0; i < box.length; i++) {
        //   var offsetTop = box[i].offsetTop
        //   var offsetBottom = box[i].offsetTop + box[i].offsetHeight
        //   if (offsetTop <= visibleCenter && offsetBottom >= visibleCenter) {
        //     this.activePage = box[i].firstChild.id
        //   }
        // }
      },
      // 选择文件
      selectFile (fileIndex){
        document.getElementById(fileIndex + '-0').scrollIntoView()
        this.fileSelectDrawerVisible = false
      },
      // 发送手机验证码
      sendSmsCode () {
        this.$http({
          url: this.$http.adornUrl('api/web/sms'),
          method: 'post',
          data: this.$http.adornData({
            taskId: this.taskId
          })
        }).then(({data}) => {
          if (data.code === 0) {
            this.smsCodeCountdown(data.data.seconds)
          } else {
            this.message('error', data.msg)
          }
        }).catch((e) => {
          this.message('error', '验证码获取失败')
        })
      },
      // 获取验证码倒计时1分钟
      smsCodeCountdown (n) {
        this.smsLeftSeconds = n
        this.smsCodeTimer = setInterval(() => {
          this.smsLeftSeconds--
          if (this.smsLeftSeconds <= 0) {
            this.smsBtnText = '重新获取'
            clearInterval(this.smsCodeTimer)
          }
        }, 1000)
      },
      // 页面倒计时
      pageCountdown () {
        this.pageTimer = setInterval(() => {
          this.pageLeftSeconds--
          if (this.pageLeftSeconds <= 0) {
            clearInterval(this.pageTimer)
            clearInterval(this.smsCodeTimer)
            this.signDialogVisible = false
            this.toErrorPage()
          }
        }, 1000)
      },
      toErrorPage () {
        this.error('未在规定的时间内完成签署，请关闭当前页面后重试')
      },
      padLeft (val) {
        if (val < 10) {
          return '0' + val
        }
        return '' + val
      },
      message (type, message) {
          var options = {
              type: type,
              message: message,
              customClass: 'el-message-h5'
          }
          this.$message(options)
      },
      alert(message, type) {
          var options = {
              customClass: 'el-message-box-h5',
              dangerouslyUseHTMLString: true,
              center: true,
              showClose: false
          }
          if (type == 'success') {
              return this.$alert('<img src="' + this.baseUrl + 'static/images/success.png"/><div>'+message+'</div>', '', options)
          } else {
              return this.$alert('<img src="' + this.baseUrl + 'static/images/fail.png"/><div>'+message+'</div>', '', options)
          }
      },
      confirm () {
        if (this.smsCode === '') {
            this.message('error', '请输入验证码')
            return false
        }

        var smsCode = this.smsCode
        if (this.task.signMethod == 2) {
          // 短信签，使用SMSCode对taskId进行签名
          smsCode = this.getSign(this.taskId,this.smsCode)
        }

        this.btnLoading = true
          this.$http({
              url: this.$http.adornUrl('api/web/confirm'),
              method: 'post',
              data: this.$http.adornData({
                type: this.task.signMethod,
                taskId: this.taskId,
                smsCode: smsCode,
                userStamp: this.handwritten ? this.userStamp.split(',')[1] : ''
              })
          }).then(({data}) => {
              this.btnLoading = false
              if (data.code === 0) {
                  this.signDialogVisible = false
                  this.signed = true
                  this.alert('签署成功', 'success').then(() => {
                      this.callback('success')
                  })
              } else {
                  this.message('error', data.msg);
              }
          }).catch((e) => {
              this.btnLoading = false
              this.alert('系统异常，请稍后重试').then(() => {
                this.goback()
              })
          })
      },
      cancel () {
        this.btnLoading = false
        this.signDialogVisible = false
      },
      signNow () {
        if (this.handwritten) {
          this.handwrittenDialogVisible = true
        } else {
          this.showSignDialog()
        }
      },
      showSignDialog () {
        initScrollTop = document.documentElement.scrollTop || document.body.scrollTop || window.pageYOffset
        this.signDialogVisible = true
        if (this.task.signMethod === 2) {
          if (this.smsLeftSeconds <= 0) {
            this.sendSmsCode()
          }
        }
      },
      reject () {
          this.$confirm('拒签后当前任务无法继续流转、签署，请确认是否拒签？', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning',
              customClass: 'el-message-box-confirm-h5'
          }).then(() => {
              this.contentLoading = true
              this.$http({
                url: this.$http.adornUrl('api/web/reject'),
                method: 'post',
                data: this.$http.adornData({
                  taskId: this.taskId
                })
              }).then(({data}) => {
                  if (data.code === 0) {
                    setTimeout(() => {
                      this.contentLoading = false;
                      this.alert('拒签成功','success').then(() => {
                        this.callback('reject')
                      })
                    },1000)
                  } else {
                    setTimeout(() => {
                      this.contentLoading = false;
                        this.alert(data.msg).then(() => {
                            this.goback()
                        })
                    },1000)
                  }
              }).catch((e) => {
                  setTimeout(() => {
                    this.contentLoading = false;
                    this.alert('拒签失败!').then(() => {
                        this.goback()
                    })
                  },1000)
              })
          }, () => {
          })
      },
      useSignature (signature) {
        this.userStamp = signature
        this.handwrittenDialogVisible = false
        this.showSignDialog()
      },
      inputBlur () {
        document.documentElement.scrollTop = initScrollTop
        document.body.scrollTop = initScrollTop
      },
      showQrcodeDialog () {
        this.getCodeBtnLoading = true
        if (setupWebViewJavascriptBridge) {
          setupWebViewJavascriptBridge((bridge) => {
            bridge.callHandler('getCode', this.qrcodeUrl, () => {
              this.getCodeBtnLoading = false
            })
          })
        }
      },
      signDialogBeforeClose (done) {
        this.getCodeBtnLoading = false
        done();
      },
      pageback (){
        this.goback()
      },
      callback (type) {
        // 签署后回调
        var callbackUrl = this.task.callbackUrl || ''
        if (callbackUrl) {
          if (callbackUrl.indexOf('?')>-1) {
            callbackUrl += '&type='+ type
          } else {
            callbackUrl += '?type='+ type
          }
        }

        if(window.isWeixin){
          // 微信
          wx.miniProgram.getEnv((res) => {
            if (res.miniprogram) {
              // 小程序
              if (callbackUrl) {
                if (callbackUrl.startsWith('/')) {
                  // 如果是小程序链接,跳转到小程序链接
                  wx.miniProgram.redirectTo({url: callbackUrl})
                } else {
                  // 如果是普通链接，跳转到普通链接
                  location.replace(callbackUrl)
                }
              } else {
                wx.miniProgram.navigateBack()
              }
            } else {
              // 微信网页
              if (callbackUrl) {
                // 有回调地址,跳转到回调地址
                location.replace(callbackUrl)
              } else {
                // 没有回调地址，返回上一页
                if (history.length>1) {
                  // 有上一个页面，返回上一个页面
                  history.back()
                } else {
                  // 没有上一个页面，关闭网页
                  wx.closeWindow()
                }
              }
            }
          })
        } else if (window.isJsgz) {
          // 即时公证
          setupWebViewJavascriptBridge((bridge) => {
            // 关闭验证码弹窗
            bridge.callHandler('closeTopAlert')
          })
          // 后退
          history.back()
        } else {
          // 浏览器网页
          if (callbackUrl) {
            // 有回调地址,跳转到回调地址
            location.replace(callbackUrl)
          } else {
            // 没有回调地址，返回上一页
            history.back()
          }
        }
      }
    }
  }
</script>

<style lang="scss">
  .sign-index-h5{
    padding-top: 76px;
    padding-bottom: 50px;
    background: #f5f5f5;
    height: 100%;
  }
  .mui-pull-left {
    float: left;
  }
  .mui-icon {
    font-family: Muiicons;
    font-size: 24px;
    font-weight: 400;
    font-style: normal;
    line-height: 1;
    display: inline-block;
    text-decoration: none;
    -webkit-font-smoothing: antialiased;
  }
  .mui-title {
    font-size: 17px;
    font-weight: 500;
    line-height: 44px;
    position: absolute;
    display: block;
    width: 100%;
    margin: 0 -10px;
    padding: 0;
    text-align: center;
    white-space: nowrap;
    color: #FFF;
  }
  .mui-bar {
    position: fixed;
    z-index: 10;
    right: 0;
    left: 0;
    height: 44px;
    padding-right: 10px;
    padding-left: 10px;
    border-bottom: 0;
    background-color: #f7f7f7;
    -webkit-box-shadow: 0 0 1px rgba(0,0,0,.85);
    box-shadow: 0 0 1px rgba(0,0,0,.85);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    .mui-icon {
      font-size: 24px;
      position: relative;
      z-index: 20;
      padding-top: 10px;
      padding-bottom: 10px;
      .icon-svg{
        font-size: 20px;
        color: #FFF;
      }
    }
    .mui-title {
      right: 40px;
      left: 40px;
      display: inline-block;
      overflow: hidden;
      width: auto;
      margin: 0;
      text-overflow: ellipsis;
    }
  }
  .mui-bar-nav {
    top: 0;
    -webkit-box-shadow: 0 1px 6px #ccc;
    box-shadow: 0 1px 6px #ccc;
    background: #4897f8;
  }
  .mui-bar-nav.mui-bar .mui-icon {
    margin-right: -10px;
    margin-left: -10px;
    padding-right: 10px;
    padding-left: 10px;
  }
  .header{
    position: fixed;
    top: 44px;
    left: 0;
    width: 100%;
    font-size: 12px;
    line-height: 32px;
    height: 32px;
    color: #6f4900;
    text-align: center;
    z-index: 999;
    background: #f5f5f5;
  }
  .footer{
    position: fixed;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 10px;
    z-index: 999;
    display: flex;
    .el-button{
      width: 100%;
    }
  }
  .page-countdown{
    position: fixed;
    right: 0;
    top: 15%;
    width: auto;
    font-size: 14px;
    line-height: 2.2;
    border-radius: 50px 0 0 50px;
    background: #ababab;
    color: #FFF;
    padding: 0 10px;
  }
  .file-select{
    position: fixed;
    bottom: 20%;
    right: 10px;
    .icon-svg__link{
      font-size: 12px;
      vertical-align: middle;
    }
  }
  .file-select-drawer{
    .file-list{
      border-top: 1px solid #eee;
      .file-item{
        border-bottom: 1px solid #eee;
        padding: 10px 20px;
      }
    }
  }
  .sign-dialog-h5{
    .dialog-header{
      display: flex;
      .logo{
        height: 60px;
      }
      .btn-getcode{
        flex: 1;
        text-align: right;
        position: relative;
        .icon-svg__refresh{
          vertical-align: top;
          margin-right: 4px;
        }
        .el-button{
          position: absolute;
          right: 0;
          bottom: 10px;
          border-radius: 50px;
        }
      }
    }
    .dialog-body{
      .tips{
        margin-top: 15px;
        color: #666666;
      }
      .mobile-tips{
        color: #6ca0d7;
        margin-bottom: 10px;
      }
    }
    .dialog-footer{
      margin-top: 15px;
      .el-button{
        width: 48%;
      }
    }
  }
  .content {
    background-color: #f5f5f5;
    overflow-y: auto;
    flex: 1;
    padding: 10px;
    padding-top: 0;
    .file-wrapper{
      width: 100%;
      margin: 0 auto;
      .page-view{
        margin-bottom: 12px;
        .paper{
          width:100%;
          background-color: #ffffff;
          position: relative;
          border-radius: 4px;
          overflow: hidden;
          .paper-cover{
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            .img-stamp{
              position: absolute;
              -webkit-filter: grayscale(1); /* Webkit */
              filter: gray; /* IE6-9 */
              filter: grayscale(1); /* W3C */
            }
            .sign-text{
              position: absolute;
              font-weight: 900;
            }
          }
        }
        .paper-bottom{
          position: relative;
          text-align: center;
          font-size: 12px;
          margin-top: 5px;
          color: #333333;
          .doc-index{
            font-size: 12px;
          }
          .doc-name{
            font-size: 12px;
            float: left;
          }
        }
      }
    }
  }
  .qrcode-dialog-content{
      text-align: center;
      .top-tips{
          line-height: 1.5;
          color: #000;
          margin-bottom: 20px;
      }
      .bottom-tips{
          line-height: 1.5;
          color: #666;
          margin-top: 15px;
      }
      .icon-svg__close{
          font-size: 32px;
          position: absolute;
          bottom: -60px;
          left: 50%;
          transform: translateX(-50%);
          color: #FFF;
          cursor: pointer;
      }
  }
  .sign-tips{
    position: absolute;
    top: -25px;
    text-align: center;
    width: 100%;
    background: rgba(0,0,0,0.4);
    margin-left: -10px;
    padding: 4px 0;
    color: #FFF;
  }
</style>
